/*定义公共部分通用css，默认所有页面都需要加载 2016/08/17*/

* {
  box-sizing: border-box;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figure,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
}

html {
  height: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  background: #f5f5f5;
}

input,
select,
textarea,
button {
  font-family: "Arial,Helvetica,Microsoft YaHei";
}

select,
input,
button {
  -webkit-appearance: none;
  vertical-align: middle;
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
i,
b {
  font-weight: normal;
}

i {
  font-style: normal;
}

ul,
ol,
li {
  list-style-type: none;
}

img {
  border: 0;
  vertical-align: middle;
  max-width: 100%;

}

img:not([src]) {
  opacity: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}


a:link,
a:visited {
  color: inherit;
  text-decoration: none;
}

a:hover,
a:active {
  color: inherit;
  text-decoration: none;
}

/* 取消苹果圆角 */
input,
button,
textarea {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  border-radius: 0;
}

input {
  font-size: 14px;
}

button {
  padding: 0;
}

p {
  padding: 0;
  margin: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}

input[disabled],
textarea[diabled] {
  color: var(--font-normal);
  -webkit-text-fill-color: var(--font-normal);
  opacity: 1;
}

/* placeholder  */
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: var(--disabled);
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: var(--disabled);
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: var(--disabled);
}

input[disabled]::-webkit-input-placeholder,
textarea[diabled]::-webkit-input-placeholder {
  color: var(--disabled);
  -webkit-text-fill-color: var(--disabled);
}

button,
input[type='button'] {
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[readonly] {
  -webkit-user-select: none
}


.h {
  display: none;
  visibility: visible;
}

.fix {
  *zoom: 1;

}

.fix:after {
  clear: both;
  content: "";
  display: table;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.pos-r {
  position: relative;
}

.pos-a {
  position: absolute;
}

.pos-f {
  position: fixed;
}

.tc {
  text-align: center !important;
}

.tl {
  text-align: left !important;
}

.tr {
  text-align: right !important;
}

.cur {
  cursor: pointer;
}

.align-center {
  align-content: center;
  align-items: center;
  justify-content: center;
}

.dis-show {
  display: block !important;
}

.disShow {
  display: inline-block !important;
}

.dis-flex {
  display: flex !important;
}

.dis-hide {
  display: none !important;
}

.dis-inb {
  display: inline-block !important;
}

.dis-b {
  display: block;
}

.va-t {
  vertical-align: top;
}

.va-m {
  vertical-align: middle;
}

.va-b {
  vertical-align: bottom;
}

.m0 {
  margin: 0 !important;
}

.mt0 {
  margin-top: 0 !important;
}

.ml0 {
  margin-left: 0 !important;
}

.ml12 {
  margin-left: 12px;
}

.mr0 {
  margin-right: 0 !important;
}

.mr24 {
  margin-right: 24px !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb8 {
  margin-bottom: 8px;
}

.mt300 {
  margin-top: 300px !important;
}

.p0 {
  padding: 0 !important;
}

.pt0 {
  padding-top: 0 !important;
}

.pl0 {
  padding-left: 0 !important;
}

.pr0 {
  padding-right: 0 !important;
}

.plr12 {
  padding: 0 12px;
}

.pb0 {
  padding-bottom: 0 !important;
}

.txt-hint {
  color: var(--color-hint);
}

.txt-red {
  color: var(--orangey-red) !important;
}

.txt-green {
  color: var(--font-green) !important;
}

.txt-tips {
  color: var(--font-tips) !important;
}

.txt-title {
  color: var(--font-title) !important;
}

.txt-main-color {
  color: var(--color) !important;
}

.txt-hover {
  cursor: pointer;

  &:hover {
    color: var(--color) !important;
  }
}

.bg-white {
  background: #fff !important;
}

.flex {
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
}

.flex-children {
  flex: 1;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.ellipsis-2 {
  display: -webkit-flex;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal !important;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
  white-space: normal;
}

.bl {
  border-left: 1px solid var(--border-deep);
}

.br {
  border-right: 1px solid var(--border-deep);
}

.bt {
  border-top: 1px solid var(--border-deep);
}

.bb {
  border-bottom: 1px solid var(--border-deep);
}

.b0 {
  border: 0 none;
}

//遮罩
.mask, .mask-son, .mask-pop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -1px;
  right: 0;
  background: rgba(26, 26, 26, 0.30);
}

.mask {
  z-index: 3;
}

.mask-son {
  z-index: 5;
}

.mask-pop {
  z-index: 7;
}

.page-wrap {
  max-width: 1200px;
  margin: 0 auto;
}


.page-box-wrap {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}


.page-body {
  flex: 1;
  width: 1200px;
  margin: 0 auto;
  display: flex;

  .page-main {
    flex: 1;
    width: 0;
  }
}

.page-max {
  width: 100vw;
}

.bg-white {
  background: #fff;
}

.login-modal {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 5;
  width: 304px;
  transform: translate(-50%, -50%);

  .login-modal-title {
    width: 304px;
    height: 48px;
    line-height: 48px;
    font-size: 18px;
    text-align: center;
    background: #fff;
    color: var(--font-title);
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid var(--border-deep);
  }

  .login-modal-body {
    padding: 32px 0 32px;
    text-align: center;
    background: #fff;

    .btn {
      width: 240px;
      height: 50px;
      line-height: 1;
      margin-bottom: 16px;
      border-radius: 50px;
      font-size: 18px;
      color: var(--font-normal);
      border: 1px solid var(--border-deep);
    }

    .btn-confirm {
      background: var(--color);
      color: #fff;

      &:hover {
        background: var(--color-active);
      }


    }
  }

  .close {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 22px;
    height: 22px;
    cursor: pointer;

    &:after,
    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      background: #999;

    }

    &:hover:after,
    &:hover:before {
      background: #666;
    }

    &:after {
      width: 1px;
      height: 14px;
    }

    &:before {
      width: 14px;
      height: 1px;
    }

  }
}

.null-list-box {
  text-align: center;
  padding-top: 250px;
  padding-bottom: 1em;

  img {
    width: 200px;
    height: 162px;
  }

  .null-tips {
    font-size: 14px;
    color: var(--font-tips);
  }
}


.inquiry-model {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 5;
  width: 300px;
  min-height: 200px;
  border-radius: 6px;
  transform: translate(-50%, -50%);
  background: #fff;
  overflow: hidden;
  padding-bottom: 10px;

  .close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 16px;
    height: 16px;
    cursor: pointer;

    &:hover {
      opacity: .8;
    }

    &:after,
    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      background: var(--font-tips);
    }

    &:after {
      width: 1px;
      height: 16px;
    }

    &:before {
      width: 16px;
      height: 1px;
    }
  }

  .inquiry-model-title {
    padding: 32px 24px;
    font-size: 16px;
    text-align: center;
    background: #fff;
    color: var(--font-title);
    font-weight: bold;
    word-break: break-all;
  }

  .btn {
    display: block;
    width: 200px;
    height: 40px;
    margin: 0 auto 16px;
    background: var(--border-light);
    border: 1px solid var(--border-deep);
    color: var(--font-title);
    border-radius: 50px;
    cursor: pointer;

    &:hover {
      opacity: .8;
    }
  }
}

.page-custom-modal {
  .ant-modal-header {
    padding: 14px 24px;
    text-align: center;
    background: #E3E3E3;
  }

  .ant-modal-title {
    font-size: 18px;
    color: var(--font-title);
  }

  .ant-modal-close-x {
    width: 48px;
    height: 48px;
  }
}

.model-height-450 {
  .ant-modal-content {
    .ant-modal-body {
      height: 450px;
      overflow: auto;
    }
  }
}

.model-height-573 {
  .ant-modal-content {
    .ant-modal-body {
      height: 573px;
      overflow: auto;
    }
  }
}

.null-tips-box {
  text-align: center;
  padding-top: 100px;

  .null-icon {
    display: inline-block;

    .svg-icon {
      width: 90px;
      height: 90px;
    }
  }

  .txt {
    font-size: 14px;
    color: var(--font-normal);
  }
}

.user-card {
  display: flex;
  align-content: center;
  align-items: center;

  .head-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 8px;

    img, .svg-icon {
      width: 100%;
      height: 100%;
    }
  }

  .main-box {
    flex: 1;
    font-size: 12px;
    line-height: 1.2;

    .text-title {
      color: var(--font-title);
    }

    .text-normal {
      margin-top: 4px;
      color: var(--font-normal);
    }

    .text-tips {
      margin-top: 4px;
      color: var(--font-tips);
    }
  }
}

.statistic-box-card {
  display: flex;

  &.statistic-box-card-divider {
    .statistic-item-card {
      &:first-child {
        &:before {
          display: none;
        }
      }

      &:before {
        content: "";
        width: 1px;
        height: 24px;
        background: #dcdcdc;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
  }

  .statistic-item-card {
    flex: 1;
    width: 0;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
    position: relative;

    .statistic-title {
      font-size: 12px;
      color: var(--font-tips);
      margin-top: 4px;
    }

    .statistic-main {
      font-size: 20px;
      color: var(--font-title);
      font-weight: bold;
    }

    .txt-hint {
      color: var(--font-high);
    }
  }
}

.cascader-box {
  width: 100%;
}

.cont-table {
  table {
    width: 100%;
    border: 1px solid var(--border-deep);
    table-layout: fixed;
  }

  th {
    height: 40px;
    vertical-align: middle;
    background: #f5f5f5;
    border-bottom: 1px solid var(--border-deep);
    font-size: 14px;
    color: var(--font-normal);
    padding-left: 14px;
  }

  tr {
    &:last-child {
      border-bottom: 0 none;
    }
  }

  td {
    font-size: 14px;
    color: var(--font-normal);
    padding-left: 14px;
    height: 40px;
    vertical-align: middle;
    border-bottom: 1px solid var(--border-deep);

    .info-box {
      padding: 8px 0;
      font-size: 14px;

      .title {
        color: var(--font-title);
        margin-bottom: 4px;
      }

      .tips {
        color: var(--font-tips);
      }
    }

    .txt {
      display: inline-block;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .img-box {
      display: inline-block;
      vertical-align: top;
      width: 60px;
      height: 60px;
      border: 1px solid var(--border-deep);
      text-align: center;
      line-height: 60px;
      margin: 8px 8px 8px 0;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .ope {
      width: auto;
      display: inline-block;
      margin-right: 8px;
      cursor: pointer;

      &:hover {
        color: var(--color);
      }
    }
  }
}

.page-confirm-pay-modal {
  .ant-modal-content {
    position: relative;
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 430px;
      .img-box {
        width: 454px;
        height: 318px;
        margin-bottom: 20px;
        .close {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          position: absolute;
          right: 0;
          top: 0;
          width: 50px;
          height: 50px;
          font-size: 32px;
          transform: rotate(45deg);
          &:hover {
            cursor: pointer;
          }
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .confirm-txt {
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        color: #333;
      }
    }
    .ant-modal-footer {
      display: flex;
      justify-content: center;
      padding: 30px 16px;
      button {
        width: 200px;
        height: 40px;
      }
    }
    .ant-modal-footer button + button {
      margin-left: 30px;
    }
  }
}


// 轮播图样式拓展
@import "theme";
@import "antd-reset";
@import "home";
@import "inquire";
@import "goods";
@import "cart";
@import "order";
@import "user";
@import "experience";
