.inquire-page-box {
  height: 100%;
  //min-height: 80vh;
  background: #fff;
  padding: 16px 16px 80px;
  position: relative;

  .ant-spin-nested-loading > div > .ant-spin {
    top: 50%;
    transform: translateY(-50%);
  }
}

.spin-box {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);
  z-index: 6;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.inquire-head-wrap {
  display: flex;
  justify-content: space-between;

  .search-wrap {
    width: 578px;
    border: 1px solid var(--color);
    position: relative;

    .search-box {
      .txt {
        padding-right: 120px;

        .ant-input-suffix {
          right: 132px;
          z-index: 0;
          line-height: 38px;
        }
      }
    }

    .ant-input {
      border: 0 none;
      height: 38px;
    }

    .search-btn {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 120px;
      background: var(--color);
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
      border: 0 none;

      &:hover {
        opacity: 0.9;
      }

      &.search-btn-disabled {
        background: var(--disabled);
      }

      i {
        font-size: 18px;
        margin-right: 4px;
      }
    }

    .search-dropdown-box {
      position: absolute;
      top: 49px;
      left: 0;
      right: 0;
      height: 344px;
      background: #fff;
      box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.1);
      border: 1px solid #f0f0f0;
      z-index: 1;

      .search-dropdown-wrap {
        padding: 16px;
      }

      .log-item {
        font-size: 12px;
        cursor: pointer;
        padding: 12px 0;
        border-bottom: 1px solid var(--border-deep);

        &:first-child {
          padding-top: 0;
        }

        .tit {
          color: var(--font-title);
        }

        .txt {
          color: var(--font-tips);
        }

        .del {
          &:hover {
            color: var(--color);
          }
        }
      }
    }
  }

}
.user-info {
  display: flex;
  align-content: center;
  align-items: center;
  font-size: 14px;
  color: var(--font-title);
  cursor: pointer;

  .txt {
    margin-left: 6px;
  }

  .btn {
    margin-left: 6px;
    color: var(--color);
    cursor: pointer;
  }
}

.inquire-null-tips-box {
  width: 100%;
  text-align: center;
  padding-top: 80px;
}

.car-info-box {
  display: flex;
  padding: 16px;
  background: #fafafa;
  margin-top: 16px;
  position: relative;

  &:before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    background: #fafafa;
    position: absolute;
    top: -8px;
    left: 40px;
    transform: rotate(45deg);
  }

  .car-img {
    width: 50px;
    height: 50px;
    background: #fff;
    text-align: center;
    line-height: 50px;
    margin-right: 16px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .car-info-wrap {
    flex: 1;

    .title {
      font-size: 16px;
      color: var(--font-title);
      font-weight: bold;
      line-height: 1;
      margin-bottom: 8px;
    }

    .car-list {
      margin-bottom: 8px;

      .car-item {
        display: inline-block;
        vertical-align: middle;
        min-width: 160px;
        padding: 0 1em;
        height: 24px;
        margin-right: 12px;
        text-align: center;
        line-height: 24px;
        border-radius: 2px;
        font-size: 12px;
        border: 1px solid var(--border-deep);
        color: var(--font-title);
        cursor: pointer;

        &:hover,
        &.active {
          border-color: var(--color);
          color: var(--color);
        }
      }
    }
  }
}


.parts-box-wrap {
  margin-top: 32px;

  .title {
    padding-bottom: 6px;
    border-bottom: 1px solid var(--border-deep);
    position: relative;
    font-size: 14px;
    font-weight: bold;
    color: var(--font-title);
    display: flex;
    align-items: center;

    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 2px;
      height: 14px;
      background: var(--font-title);
      margin-right: 6px;
    }
  }

  .parts-box-main {
    padding-top: 16px;

    .search-box-wrap {
      .input-wrap {
        display: inline-block;
        vertical-align: middle;
        width: 342px;
        position: relative;

        .input-area {
          display: flex;

          .txt {
            flex: 1;
            height: 32px;
            border: 1px solid var(--border-deep);
            border-radius: 4px 0 0 4px;
            padding-left: 1em;
            font-size: 14px;
            color: var(--font-title);
          }

          .search-btn {
            display: inline-block;
            width: 52px;
            height: 32px;
            text-align: center;
            line-height: 32px;
            background: var(--color);
            color: #fff;
            font-size: 20px;
            cursor: pointer;

            &:hover {
              opacity: 0.8;
            }
          }
        }

        .result-wrap {
          position: absolute;
          top: 32px;
          left: 0;
          right: 0;
          height: 343px;
          background: #fff;
          box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.1);
          border: 1px solid rgba(240, 240, 240, 1);
          padding: 0 14px;

          .spin-box {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          li {
            font-size: 14px;
            color: var(--font-title);
            line-height: 32px;
            border-bottom: 1px solid var(--border-deep);
            cursor: pointer;

            &:hover {
              color: var(--color);
            }
          }
        }
      }

      .epc-btn-group{
        display: inline-block;
        
        vertical-align: middle;
        margin-left: 16px;

        .flex_box{
          display: flex;
          align-items: center;
        }
      }

      .epc-btn {
        font-size: 14px;
        color: var(--color);
        cursor: pointer;
        margin-right: 6px;

        .iconfont {
          font-size: 18px;
        }
      }
    }
  }
}

.parts-result-box {
  margin-top: 24px;

  table {
    width: 100%;
    border: 1px solid var(--border-deep);
    table-layout: fixed;
    margin-bottom: 16px;
  }

  th {
    height: 40px;
    line-height: 40px;
    background: #f5f5f5;
    font-weight: normal;
    padding: 0 16px;
    border-bottom: 1px solid var(--border-deep);
    color: var(--font-normal);

    &:nth-child(2),
    &:nth-child(3) {
      width: 15%;
    }

    .price {
      color: var(--font-high);

      &:hover {
        color: var(--font-high);
      }
    }

    .epc-btn {
      cursor: pointer;
      float: left;

      .svg-icon {
        width: 30px;
        height: 30px;
      }
    }

    span {
      display: inline-block;
      margin-left: 10px;
      cursor: pointer;

      &:hover {
        color: var(--color);
      }

      .iconfont {
        font-size: 12px;
      }
    }

    &:last-child {
      text-align: right;
    }
  }

  td {
    padding: 12px 16px;
    border-bottom: 1px solid var(--border-deep);
    color: var(--font-high);

    p {
      margin-bottom: 0;
    }

    .tips {
      font-size: 14px;
      color: var(--font-tips);
    }

    .info-box {
      .tit {
        font-size: 14px;
        color: var(--font-title);
        margin-bottom: 1em;
      }

      .tips {
        font-size: 14px;
        color: var(--font-tips);

        span {
          display: inline-block;
          vertical-align: top;
          margin-right: 1em;

          i {
            color: var(--font-high);
          }
        }
      }
    }

    .price {
      display: inline-block;
      margin-right: 8px;
      font-size: 16px;
    }

    .cost {
      font-size: 12px;
      color: var(--font-tips);
      text-decoration: line-through;
    }

    .cart {
      cursor: pointer;
    }

    &:last-child {
      text-align: right;
    }
  }
}



.epc-detail-box {
  width: 100%;

  .img-box-wrap {
    width: 100%;
    text-align: center;

    .img-box {
      display: inline-block;
      width: 400px;
      height: 400px;
      text-align: center;
      line-height: 400px;
      overflow: hidden;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .info-box {
    margin-top: 10px;

    .descriptions-box {
      border: 1px solid var(--border-deep);

      .descriptions-item-box {
        height: 32px;
        line-height: 32px;
        border-bottom: 1px solid var(--border-deep);
        margin: 0;
        padding: 0;

        &:last-child {
          border-bottom: 0 none;
        }

        .descriptions-title {
          width: 90px;
          border-right: 1px solid var(--border-deep);
          margin: 0;
          padding-left: 1em;
        }

        .descriptions-main {
          padding-left: 1em;
        }
      }
    }
  }
}
.empt_box{
  width: 16px;
}

.inquire-foot-wrap {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  border-top: 1px solid #f2f2f2;
  background: #fcfcfc;
  box-sizing: border-box;

  .btn-group {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;

    .btn {
      border: 0 none;
      width: 150px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 16px;
      color: #fff;
      background: var(--color);

      &[disabled] {
        background: var(--disabled);
        color: #fff;
      }

      &.minor {
        color: var(--color);
        background: var(--color-hover);
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.inquiry-list-page-box {
  height: 100%;
  background: #fff;
  margin-top: 14px;
}

.inquiry-list-page-main {
  padding: 16px;

  .inquiry-list-page-main-head {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-bottom: 8px;

    .tabs-box {
      .tab {
        display: inline-block;
        vertical-align: middle;
        margin: 0 14px;
        font-size: 14px;
        color: var(--font-normal);
        padding-bottom: 4px;
        border-bottom: 2px solid transparent;
        cursor: pointer;

        &:hover {
          color: var(--color);
        }

        &.active {
          color: var(--color);
          border-color: var(--color);
        }
      }
    }

    .search-box {
      .input-wrap,
      .select-box {
        display: inline-block;
        vertical-align: middle;
      }

      .input-wrap {
        margin-right: 8px;
      }
    }
  }

  .inquiry-list-page-main-body {
    border: 1px solid var(--border-deep);

    .inquiry-list-card {
      padding: 16px;
      border-bottom: 1px solid var(--border-deep);
      font-size: 14px;
      display: flex;
      cursor: pointer;

      &:hover {
        background: var(--color-hover);
      }

      &:last-child {
        border-bottom: 0 none;
      }

      .img-box {
        width: 50px;
        height: 50px;
        text-align: center;
        line-height: 50px;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .main-box {
        flex: 1;
        width: 0;
        padding: 0 16px;
        color: var(--font-tips);

        .title {
          margin-bottom: 8px;

          .txt-title {
            color: var(--font-title);

            .tips {
              margin-left: 1em;
              color: var(--font-tips);
            }
          }

          .txt {
            margin-left: 1em;
            color: var(--font-normal);
          }
        }
      }

      .tips-box {
        .txt {
          margin-left: 1em;
          color: var(--font-tips);
        }
      }

      .attachment-box {
        margin-top: 8px;
        border-top: 1px solid var(--border-deep);
        padding-top: 8px;

        .tit {
          font-size: 14px;
          color: var(--font-tips);
          margin-bottom: 4px;
        }

        .remark-box {
          font-size: 14px;
          color: var(--font-title);
          line-height: 1.5;
          margin-bottom: 4px;
          word-break: break-all;
          white-space: normal;
        }

        .attachment-main {
          .img-box {
            display: inline-block;
            vertical-align: top;
            border: 1px solid var(--border-deep);
            width: 80px;
            height: 80px;
            text-align: center;
            line-height: 80px;
            margin-right: 8px;

            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
      }
    }
  }

  .inquiry-list-page-main-foot {
    margin-top: 16px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
}

.inquiry-detail-page-box {
  margin-top: 14px;
  background: #fff;
  height: 100%;
}

.inquiry-detail-page-main {
  padding: 16px;

  .car-info-box {
    align-content: center;
    align-items: center;
    font-size: 14px;
    border: 1px solid var(--border-deep);
    margin-top: 0;
    margin-bottom: 16px;
    padding: 8px 16px;

    &:before {
      display: none;
    }

    .img-box {
      width: 50px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      background: #fff;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .info-box {
      flex: 1;
      padding-left: 24px;
      color: var(--font-tips);

      span {
        margin-right: 16px;

        &:first-child {
          color: var(--font-title);
        }
      }
    }

    .text-btn {
      color: var(--font-title);
      cursor: pointer;

      &:hover {
        color: var(--color);
      }
    }
  }

  .detail-main-box {
    display: flex;

    .left-box {
      flex: 1;
      min-height: 694px;
      margin-right: 16px;
      border: 1px solid var(--border-deep);

      .txt-tips{
        padding: 8px 12px;
        cursor: pointer;
        border-bottom: 1px solid #ddd;
      }

      .part-box{
        border-bottom: 1px solid #ddd;
      }


      .loading-box{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      li {
        width: 100%;
        height: 40px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 40px;
        font-size: 14px;
        padding: 0 16px;
        color: var(--font-title);
        cursor: pointer;

        &:hover,
        &.active {
          color: var(--color);
          background: #fff;
        }
      }
    }

    .right-box {
      width: 811px;
      border: 1px solid var(--border-deep);

      .title {
        padding: 12px;
        background: #f8f8f8;
      }

      .new-label-box {
        display: flex;
        flex-direction: row;
        position: relative;
        padding: 6px 12px;
        font-size: 12px;
        color: #333;
        text-align: center;
        border: 0 solid #e3e3e3;
        border-radius: 3px;
        background-color: #fff;
        margin: 1px 24px 0;
        justify-content: space-between;

        .oe-name {
          font-size: 14px;
          font-weight: 700;
          width: 300px;
          text-align: left;
        }

        .epc-box {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          .epc-img-box {
            display: flex;
            width: 20px;
            height: 20px;
            cursor: pointer;
          }
        }
        .icon-cancel-2 {
          cursor: pointer;
        }
      }
    }
  }

  .inquiry-product-list-box {
    margin-bottom: 16px;
    border: 1px solid var(--border-deep);

    .inquiry-product-list-box-head {
      background: #f8f8f8;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: var(--font-title);
      padding: 0 16px;
      border-bottom: 1px solid var(--border-deep);

      span {
        display: inline-block;
        margin-right: 32px;
      }

      .epc-btn {
        float: right;
        line-height: 1;
        cursor: pointer;
        margin-right: 0;

        .svg-icon {
          width: 36px;
          height: 36px;
        }
      }

      .txt-hollow{
        color: var(--color);
        border: 1px solid var(--color);
        padding: 1px 2px;
        margin-right: 4px;
        border-radius: 2px;
      }

      .txt-tag{
        color: #fff;
        background: var(--color);
        padding: 1px 2px;
        margin-right: 4px;
        border-radius: 2px;
      }
    }

    .fr{
      display: flex;
      align-items: center;
      height: 100%;

      .chat-btn-box{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 6px;
        color:  var(--color);
        cursor: pointer;
        img{
          margin-right: 6px;
          width: 20px;
          height: 20px;
        }
        
  
  
      }

      .epc-btn{
        width: 20px;
        height: 20px;
      }
    }



    

    .inquiry-product-list-box-main {
      min-height: 220px;

      .goods-card-box {
        display: flex;
        padding: 16px;
        border-bottom: 1px solid var(--border-deep);
        font-size: 14px;

        .info-box {
          flex: 1;

          .tit {
            color: var(--font-title);
          }

          .tips {
              display: flex;
              align-items: center;
            span {
              margin-right: 8px;
            }
            .nail_box_fee_{
              color: var(--font-high);
            }
            .warranty_box{
              display: flex;
              align-items: center;
            }

            .iconfont {
              font-size: 14px;
              color: var(--font-high);
              margin-right: 4px;
            }
          }
        }

        .price-box {
          width: 20%;
          color: var(--font-high);

          .gold-price{
            color:#000;
          }

          .gold-car{
            margin-left: 14px;
            border-radius: 6px;
            padding: 0 3px;
            color: #f8dab5;
            background: #23211f;
          }
        }

        .add-cart {
          width: 13%;
          text-align: right;
          cursor: pointer;
          color: var(--font-title);

          &.active {
            color: var(--font-high);
          }

          .iconfont {
            font-size: 14px;
            margin-right: 4px;
          }
        }
      }

      .null-data-box {
        text-align: center;
        padding-top: 40px;

        .null-wrap {
          display: inline-block;

          .icon-box {
            .svg-icon {
              width: 90px;
              height: 90px;
            }
          }

          .tips-text {
            font-size: 14px;
            color: var(--font-normal);
          }

          .status-box {
            width: 102px;
            height: 24px;
            border: 1px solid var(--font-tips);
            text-align: center;
            line-height: 22px;
            font-size: 14px;
            color: var(--font-normal);
            border-radius: 4px;
            margin-top: 8px;
          }

          .time {
            margin-top: 8px;
            font-size: 14px;
            color: var(--font-high);
          }
        }
      }
    }
  }

  .parts-remark-box {
    background: #fff;
    height: 500px;

    &.isParts {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding-top: 14px;

      .part-item {
        display: inline-block;
        padding: 0 16px;
        vertical-align: top;
        height: 36px;
        line-height: 36px;
        background: #f5f5f5;
        border-radius: 18px;
        margin-right: 12px;
        margin-bottom: 12px;
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: center;
        overflow: hidden;
        cursor: pointer;

        &.active {
          color: var(--color-hint);
        }
      }
    }

    .parts-remark-box-head {
      background: #f8f8f8;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: var(--font-title);
      padding: 0 16px;
      border-bottom: 1px solid var(--border-deep);
    }

    .parts-remark-box-body {
      min-height: 200px;
      padding: 16px;

      .remark-wrap {
        font-size: 14px;
        color: var(--font-title);
        margin-bottom: 8px;
        word-break: break-all;
        white-space: normal;
      }

      .img-wrap {
        .img-box {
          display: inline-block;
          vertical-align: top;
          margin-right: 8px;
          width: 144px;
          height: 144px;
          border: 1px solid var(--border-deep);
          text-align: center;
          line-height: 144px;

          img {
            max-height: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
}

.key-word-search-result {
  margin-right: 8px;
  border-bottom: 1px solid #999;
  display: flex;
  justify-content: space-between;

  .goods-card-wrap-head {
    width: 100%;
    padding: 6px 12px 6px 6px;
    font-size: 14px;
    background: #fff;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  .detail-box {
    flex: 1;
  }
  

  .img-box {
    width: 40px;
    height: 40px;
    text-align: center;
    margin-left: 8px;
    border: 1px solid #666;
  }
}

.remark-pop-body {
  .text-area-wrap {
    margin-bottom: 16px;
  }
}

@media all and (max-height: 768px) {
    .structure-chart-detail-box
    .structure-chart-detail-body
    .structure-chart-detail-img-box
    .img-box {
    width: 240px;
    height: 240px;
    line-height: 240px;
  }
}

.epc-detail-modal{
  width: 84vw !important;
}

.tabel_info{
  display: flex;
  flex-direction: column;
  flex: 1;
}

.epc-box-wrap{
  padding: 0 14px;
  padding-bottom: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .img-box{
    width: 40px;
    height: 40px;
    border: 1px solid #ddd;
    cursor: pointer;
  }
}

.edit_icon{
  font-size: 14px;
  line-height: 24px !important;
  display: inline-block;
  margin-left: 6px;
  cursor: pointer;
}

.list_icon{
  font-size: 14px;
  color: var(--color) !important;
}

.warranty_icon{
  width: 16px;
  height: 16px;
}